<template>
  <v-app>
    <div id="globalhead" class="d-flex justify-center pa-4">
      <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://www.golfclub-schmallenberg.de/wp-content/uploads/2019/01/logo-website.png"
          transition="scale-transition"
          width="180"
      />

    </div>

    <v-main>
      <Calculator/>
      <v-container>
        <v-col>
          <a v-if="!this.isApp" href="https://www.touridat.com/golf" target="_blank">
            <v-img alt="td-banner-click" transition="scale-transition" contain
                   src="/img/touriGolf-banner-cta.jpg"></v-img>
          </a>
          <v-img v-if="this.isApp" alt="td-banner" class="shrink mr-2" transition="scale-transition" contain
                 src="/img/touriGolf-banner-greetings.jpg"></v-img>
        </v-col>
      </v-container>
    </v-main>
    <v-footer class="justify-center" inset dark>
      <v-icon small dark v-on:click="setFullscreen">mdi-fullscreen</v-icon>
    </v-footer>
  </v-app>
</template>

<script>
import Calculator from "@/components/Calculator";
import helper from "@/helper/helper";

export default {
  name: "App",
  components: {
    Calculator,
  },

  data: () => ({
    //
  }),
  methods: {
    setFullscreen() {
      if (document.fullscreenElement === null) {
        helper.openFullscreen();
      } else {
        helper.closeFullscreen();
      }
    }
  },
  computed: {
    isApp: () => {
      return helper.isAppMode();
    }
  }
};
</script>

<style scoped>

</style>
