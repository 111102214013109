<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="Vorgabe in Spielvorgaben" v-bind:key="Vorgabe.id">
        <v-card max-width="400" :class="Vorgabe.Abschlag">
          <v-card-title>{{ Vorgabe.Platz }}</v-card-title>
          <v-card-subtitle>
            Ihr Playing Handicap: <strong>{{ Vorgabe.Course_HCP }}</strong><br>
            <!--Abschlag: {{Vorgabe.Abschlag}}-->
            <span class="v-small-dialog">
              Von: {{ Vorgabe.HCPI_Von }} Bis: {{ Vorgabe.HCPI_Bis }}<br>
            </span>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            {{ Vorgabe.Spielvorgabe }}
            <br>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Weitere Plätze
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="Vorgabe in Spielvorgaben_More" v-bind:key="Vorgabe.id">
                  <v-card max-width="350" :class="Vorgabe.Abschlag">
                    <v-card-title>{{ Vorgabe.Platz }}</v-card-title>
                    <v-card-subtitle>
                      Ihr Playing Handicap: <strong>{{ Vorgabe.Course_HCP }}</strong><br>
                      <!--Abschlag: {{Vorgabe.Abschlag}}-->
                      <span class="v-small-dialog">
                      Von: {{ Vorgabe.HCPI_Von }} Bis: {{ Vorgabe.HCPI_Bis }}<br>
                    </span>
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text>
                      {{ Vorgabe.Spielvorgabe }}
                      <br>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HcpList",
  data() {
    return {
      show: false,
    }
  },
  props: {
    Spielvorgaben: {
      type: Array
    },
    Spielvorgaben_More: {
      type: Array
    }
  }
}
</script>

<style scoped>
.v-card {
  border: 1px solid #a5a5a5 !important;
  background-color: #f8f4ea !important;
  box-shadow: none !important;
  overflow: hidden;
}

.v-card .v-card__title::after,
.v-card .v-card__title:after {
  content: '';
  width: 80px;
  height: 80px;
  position: absolute;
  right: -43px;
  top: -43px;
  border-radius: 4px;
  transform: rotate(-45deg);
  border: 1px solid #a5a5a5;
}

.v-card.Weiß .v-card__title::after,
.v-card.Weiß .v-card__title:after {
  background-color: white !important;
}

.v-card.Rot .v-card__title::after,
.v-card.Rot .v-card__title:after {
  background-color: red !important;
}

.v-card.Blau .v-card__title::after,
.v-card.Blau .v-card__title:after {
  background-color: blue !important;
}

.v-card.Gelb .v-card__title::after,
.v-card.Gelb .v-card__title:after {
  background-color: yellow !important;
}
</style>
