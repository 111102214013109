<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-container>
      <v-col>
        <h1>Spielvorgaben-Rechner</h1>
      </v-col>
      <v-card flat color="transparent">
        <v-col>
          <v-select
              v-model="gender"
              :items="gender_items"
              :rules="[v => !!v || 'Geschlecht auswählen']"
              label="Geschlecht"
              @change="changeSelect"
              required
              outlined
          ></v-select>
        </v-col>
      </v-card>
      <v-card flat color="transparent">
        <v-col>
          <v-text-field
              v-model="hcp_index"
              type="number"
              value="0,00"
              min="-4"
              max="54"
              step="0.1"
              label="Handicap-Index"
              outlined
          ></v-text-field>
        </v-col>
      </v-card>
      <v-card v-if="gender" flat color="transparent">
        <v-col>
          <v-select
              :items="color_items"
              v-model="color"
              label="Abschlag"
              :rules="[v => !!v || 'Abschlag auswählen']"
              required
              outlined
          ></v-select>
        </v-col>
      </v-card>
      <v-card
          flat
          color="transparent"
      >
        <v-col>
          <v-btn
              :disabled="!valid"
              class="mr-4 v-btn--block gcs-button"
              @click="load"
          >
            Berechnen
          </v-btn>
        </v-col>
      </v-card>
    </v-container>
    <v-spacer></v-spacer>
    <v-container>
      <HcpList v-bind:Spielvorgaben="Spielvorgaben" v-bind:Spielvorgaben_More="Spielvorgaben_More"></HcpList>
    </v-container>
  </v-form>

</template>

<script>
import HcpList from "@/components/HcpList";
import axios from "axios";
import _ from "lodash";

export default {
  name: "Calculator",
  components: {
    HcpList
  },
  data() {
    return {
      Spielvorgaben: [],
      Spielvorgaben_More: [],
      error: null,
      headers: {'Content-Type': 'application/json'},
      gender: null,
      color: null,
      hcp_index: null,
      gender_items: [
        'Herren',
        'Damen',
      ],
      color_items: null,
      valid: true,
    }
  },
  methods: {
    load() {
      this.valid = this.$refs.form.validate()
      if (this.valid) {
        try {
          const query = `Abschlag=${this.color}&Geschlecht=${this.gender}&_sort=Sortierung:ASC`;
          axios.get(`https://strapi-io-core.hub-01.touri.dev/Gcs-course-handicaps?_limit=-1&${query}`).then(
              response => {
                let filteredData = [];

                _.each(response.data, (t) => {
                  if (_.inRange(this.hcp_index, t.HCPI_Von - 0.05, t.HCPI_Bis + 0.05)){
                    filteredData.push(t);
                  }
                })

                filteredData = _.orderBy(filteredData, ['Sortierung'], ['ASC']);
                this.Spielvorgaben = filteredData.slice(0,6);
                this.Spielvorgaben_More = filteredData.slice(6,9);
              }
          )
        } catch (error) {
          this.error = error
        }
      }
    },
    changeSelect() {
      if (this.gender == "Herren") {
        this.color_items = ['Weiß', 'Gelb', 'Rot'];
      } else if (this.gender == "Damen") {
        this.color_items = ['Blau', 'Rot'];
      }
    }
  }
}
</script>

<style scoped>
  .gcs-button{
    background-color: #b4a524 !important;
    color:white!important;
    text-transform: uppercase;
  }
  .gcs-button:hover{
    background-color: #558043 !important;
  }
</style>
