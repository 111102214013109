import Bowser from "bowser";
import URI from "urijs";

export default {
    parseJSON: function (resp) {
        return (resp.json ? resp.json() : resp);
    },
    checkStatus: function (resp) {
        if (resp.status >= 200 && resp.status < 300) {
            return resp;
        }
        return this.parseJSON(resp).then((resp) => {
            throw resp;
        });
    },
    openFullscreen: function () {
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    },
    closeFullscreen: function () {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    },
    isTouchDevice: function () {
        return ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0);
    },
    isFireTablet: function () {
        const browser = Bowser.getParser(window.navigator.userAgent);
        return browser.getBrowserName() === "Amazon Silk";
    },
    isAppMode: function() {
        let currentUri = URI(window.location.href);
        return currentUri.subdomain() === "app";
    },
    showDevice: function () {
        const browser = Bowser.getParser(window.navigator.userAgent);
        console.log(`The current browser name is "${browser.getBrowserName()}"`);
    }
}
